import { useState,useEffect } from 'react';
import PropTypes from 'prop-types';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar,Typography,Button, IconButton, Tooltip, Badge } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import LogoutButton from './LogoutButton';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';
import AppStepper from './AppStepper';

import { editable_config } from 'src/editable_config';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 215;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5,0,2),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar,authState }) {
  // const {authState} = UseContextState();
  const [showNoti , setShowNoti ] =useState(false)
  const [notiMessage,setNotiMessage] = useState('')
  const [notiLink,setNotiLink] = useState('')
  const navigate = useNavigate()


useEffect(()=>{

     //========= GET DASHBOARD NOTIFY ===========
       axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/dashboard/notify`,{headers: {
          'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
        },withCredentials:true})
      .then(res=>{
          console.log("dashboard_notify=======>>>>",res.data?.dashboard_notify)
          if(res?.data?.status === true){

                 const dashboard_notify = res.data?.dashboard_notify[0]?.dashboard_notify
                setShowNoti(dashboard_notify?.display_status)
                setNotiMessage(dashboard_notify?.content)
                setNotiLink(dashboard_notify?.knowmore_link)
          }

          
      })
      .catch(err=>{
          console.log(err)

      })
  //========= GET DASHBOARD NOTIFY ===========

  //  const dashboard_notify = authState?.dashboard_notify?.dashboard_notify
  // setShowNoti(dashboard_notify?.display_status)
  // setNotiMessage(dashboard_notify?.content)
  // setNotiLink(dashboard_notify?.knowmore_link)
},[])

  const handleNavigate=()=>{
    navigate("/dashboard/plandetails")

  }
  const renewDetails = authState?.user?.purchased_date
  console.log("renewDetails=>>>>",renewDetails)
  const planName = authState?.user?.plan_details?.plan_name 
  const date1 = new Date()
  const date2 = new Date(authState?.user?.plan_details?.renew_date)
  const daysLeft = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10); 
  // const daysLeft = parseInt(authState?.user?.plan_details?.renew_date) - parseInt(authState?.user?.plan_details?.purchased_date) 
  // console.log("daysLeft=>>>>",daysLeft,'planName=>',planName)
  return (
    <RootStyle>
         <div className={showNoti ? 'top_bar_notification' : 'hide_top_bar_notification' }  >
          <p></p>
            <p className='font-capitalize-case' >{notiMessage} <span><a href={notiLink} target='_blank' style={{color:'white',textDecorationLine:'underline'}} >learn more</a></span> </p> 
            <IconButton size='small'  style={{color:'white'}} onClick={()=>setShowNoti(false)} >
          <Iconify icon="material-symbols:close" />
          </IconButton>
        </div>
      <ToolbarStyle className='navbarallitems'>
        <div className='humbarger_and_planstatus_and_btn'>
        <IconButton className='humbtn' onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */} 
        { (planName !='free' && daysLeft < 6) ? 
        <div className='flex plan_status_and_renew_btn' >
        <Typography className='plan_expired_text' variant="h5" sx={{ color: 'text.secondary', }}>
        {daysLeft == 1 ? `Your Plan Has Been Expired Tomorrow!!` : daysLeft == 0  ? `Your Plan Has Been Expired Today!!` : daysLeft < 0 ? `Your Plan Has Been Expired!!` :`Your Plan Has Been Expired Within ${daysLeft } Day!!` } 
            </Typography>

            <Button onClick={handleNavigate} className='product-btn' variant="contained" startIcon={<Iconify icon="fa-brands:telegram-plane" />}> 
            Renew Now
            </Button>

        </div>
        :
         <Typography variant="h5" color={'#212b36'} className='font-capitalize-case' >
          👋 Hi {authState?.user?.app_name} User, Welcome back
        </Typography> 
        }
        </div>
        
        <Box className='blank_box' sx={{ flexGrow: 1 }} />
        
        
        <Stack className='help_tube_and_profile' direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            {/* <LanguagePopover /> */}
    {/* <Button variant="text" className='image-guide-btn-text' startIcon={<Iconify icon="logos:youtube-icon" />}>Visit Learing Hub</Button> */}
    <Tooltip title='Tutorials & Video Training'arrow >
    <a href={editable_config.Tutorials_Link} target='_blank' ><Button variant="text" className='image-guide-btn-text'  startIcon={<Iconify icon="logos:youtube-icon" />}>Tutorials</Button></a>
    </Tooltip>    
          <div style={{display:'flex',gap:3}} >
         {/* <NotificationsPopover /> */}
         <Tooltip title='Announcements'arrow >
      <IconButton
        color={'default'}
        sx={{ width: 40, height: 40 }}
        LinkComponent={Link}
        to='/dashboard/announcements'
      >
        <Badge badgeContent={authState?.announcements_count}  color="error" >
          <Iconify icon="fluent-emoji:bell" width={30} height={30} />
        </Badge>
      </IconButton>
      </Tooltip>
 
            <AccountPopover />
          </div>
          {/* <LogoutButton /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
