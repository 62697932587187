import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UseContextState } from 'src/global/GlobalContext/GlobalContext';

const steps = [
  'App Setting',
  'App Publish',
  // 'Create an ad',
];

export default function HorizontalLabelPositionBelowStepper() {
  const {authState,getStepperDetails} = UseContextState()
  const userplanDetail = authState?.user?.plan_details?.plan_name
  const stepperDetail = authState?.stepper_details
  const navigate = useNavigate()
  React.useEffect(()=>{
    getStepperDetails()

  },[])


  return (
    <Box className='stepper_main_div' >
       <Typography variant="h5" style={{marginBottom:15}} >
        Steps To Follow
            </Typography>
      <Stepper nonLinear activeStep={0} >
        {/* {steps.map((label) => (
          <Step key={label}  >
            <StepLabel >{label}</StepLabel>
          </Step>
        ))} */}
          <Step className='cusor_pointer'  completed={(stepperDetail?.app_name && stepperDetail?.app_type && stepperDetail?.app_color && stepperDetail?.app_color && stepperDetail?.address && stepperDetail?.email) ? true :false  }
           onClick={()=>navigate('/dashboard/appsetting')} >
            <StepLabel >App Setting</StepLabel>
          </Step>
          <Step className='cusor_pointer' 
          completed={stepperDetail?.app_published_count > 0 ? true :false}
            onClick={()=>navigate('/dashboard/apppublish')} 
            >
            <StepLabel >App Publish</StepLabel>
          </Step>
          <Step className='cusor_pointer' completed={userplanDetail != 'free' ? true :false }  onClick={()=>navigate('/dashboard/plandetails')}   >
            <StepLabel >Choose Plan</StepLabel>
          </Step>
      </Stepper>
    </Box>
  );
}